import { AttachmentRule, TinRule } from "./model/TinRules";
import { FormsValidation } from "./next-gen-config-wip";

export const SERVER_API_URL = String(import.meta.env.VITE_BASE_URL);
export const AUTH_HEADER = "oa-token";
export const TIMEOUT = 60 * 1000;
export const UNAUTHORIZED = 401;
export const SERVICE_UNAVAILABLE = 503;
export const CONFIRMATION_PAGE_INDEX_WITHOUT_PAYMENT = 5;
export const CONFIRMATION_PAGE_INDEX_WITH_PAYMENT = 6;
export const DEFAULT_COUNTRY_CODES = "DEFAULT_COUNTRY_CODES";
export const ONLY_DIGITS_REGEX = /^[0-9]+$/;
export const IS_PROD = import.meta.env.VITE_ENVIRONMENT_NAME === "prod";
export const IS_LOCAL = import.meta.env.VITE_ENVIRONMENT_NAME === "LOCAL";
export const DEFAULT_MINIMUM_PHONE_NUMBER_LENGTH = 7;
export const DEFAULT_MAXIMUM_PHONE_NUMBER_LENGTH = 15;
export const DEFAULT_NAMES_PATTERN = RegExp(
  /^[\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u,
  "u"
);
export const ONLY_SPECIAL_SIGNS_REGEX = /^[$&+,:;=?@#|'ʻ`‘<>.^*()%!\s-]+$/;
export const DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".bmp",
];

export type CountryFormConfiguration = {
  readonly id: string;
  readonly cityLength: number;
  readonly addressLength: number;
  readonly firstNameLength: number;
  readonly lastNameLength: number;
  readonly mothersMaidenNameLength: number;
  readonly spouseFirstNameLength: number;
  readonly spouseMiddleNameLength: number;
  readonly spouseLastNameLength: number;
  readonly cityOfBirthLength?: number;
  readonly residentTins?: TinRule[];
  readonly nonResidentTins?: TinRule[];
  // TODO remove placeOfBirth from formsValidation after moving to personal info
  readonly forms: Required<Pick<FormsValidation["forms"], "personalInfo">> &
    Required<Pick<FormsValidation["forms"], "shippingAddress">>;
  readonly attachments?: AttachmentRule[];
  readonly [key: string]:
    | null
    | string
    | number
    | boolean
    | object
    | TinRule[]
    | undefined;
};

interface PhoneNumberRules {
  [countryCode: string]: {
    phoneNumberMinLength: number;
    phoneNumberMaxLength: number;
  };
}

export const MOBILE_RULES: PhoneNumberRules = {
  // Austria
  "43": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 11,
  },
  // Belgium
  "32": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 10,
  },
  // Czech Republic
  "420": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Cyprus
  "357": {
    phoneNumberMinLength: 8,
    phoneNumberMaxLength: 8,
  },
  // Germany
  "49": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 13,
  },
  // Italy
  "39": {
    phoneNumberMinLength: 7,
    phoneNumberMaxLength: 11,
  },
  // Poland
  "48": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Slovakia
  "421": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Spain
  "34": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 10,
  },
  // Switzerland
  "41": {
    phoneNumberMinLength: 8,
    phoneNumberMaxLength: 11,
  },
  // UK
  "44": {
    phoneNumberMinLength: 10,
    phoneNumberMaxLength: 10,
  },
};

export enum Gender {
  MALE = "male",
  FEMALE = "female",
}

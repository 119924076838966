import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  CountryFormConfiguration,
  DEFAULT_NAMES_PATTERN,
  Gender,
  ONLY_DIGITS_REGEX,
} from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";

export const Indonesia: CountryFormConfiguration = {
  id: "id-ID",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: "FIRST_NAME_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: "LAST_NAME_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "radio",
        options: [Gender.MALE, Gender.FEMALE],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: "BIRTHDAY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("id-ID"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "#####",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: 40,
  provinceRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Indonesia",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [
    {
      src: "logos/Visa.png",
      alt: "Visa logo",
    },
    {
      src: "logos/Mastercard.png",
      alt: "Mastercard logo",
    },
  ],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  showDataPrivacyAcknowledgment: true,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: true,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLastNameDisclaimer: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["id"],
  defaultCountryCode: "+62|Indonesia",
  residentTins: [
    {
      key: "ssn",
      required: true,
      minLength: 16,
      maxLength: 16,
      pattern: ONLY_DIGITS_REGEX,
      showTinDates: false,
      attachments: [
        {
          required: true,
          key: "resident-attachment-0",
          index: 0,
          type: AttachmentType.TAX_NUMBER,
        },
      ],
    },
  ],
} as const;
